<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="page_header">
    <div class="mv_cloud">
      <div class="right_area">
        <div class="img04"><img src="@/assets/front_component/images/assets/back02.png" alt="雲"></div>
      </div>
      <div class="left_area">
        <div class="img01"><img src="@/assets/front_component/images/assets/back01.png" alt="雲"></div>
      </div>
    </div>
    <div class="en_ttl en"><span>{{this.messagetype.title_en}}</span></div>
    <div class="content_wrap">
      <h2>{{this.messagetype.title_jp}}</h2>
      <div class="mascot_wrap">
        <img src="@/assets/front_component/images/assets/meguru05.png" alt="めぐる君">
      </div>
    </div>
  </div>
  <main>
    <div class="section_all_wrap">
      <div class="content_in_wrap">
        <div id="breadcrumb">
          <ul class="breadcrumb_list">
            <li><router-link :to="{ name: 'Front top' }">ホーム</router-link></li>
            <li><router-link :to="{ name: 'Front event archive', params: { type: 'event' }}">{{messagetype.title_jp}}一覧</router-link></li>
            <li><span>{{ showmessage.title }}</span></li>
          </ul>
        </div>
        <div class="content">
          <div class="innar">
            <div class="post_header">
              <div class="post_header_icon"><img :src="iconPath" :alt="messagetype.title_jp"></div>
              <div class="post_header_day">
                <span class="post_header_day_time">
                  <img src="@/assets/front_component/images/assets/board01.png" alt="時間">
                  <time class="en" :datetime="formatDate_time(showmessage.created_at)">{{ formatDate(showmessage.created_at) }}</time>
                </span>
                <span class="post_header_day_name" v-if="from_user"><span v-if="from_user.group" style="display: inline;">{{ from_user.group.name }}：</span>{{ from_user.last_name }} {{ from_user.first_name }}</span>
              </div>
            </div>
            <article class="post_area">
              <h1>{{ showmessage.title }}</h1>

              <div class="eyecatch">
								<img id="img_tag_id">
							</div>

              <table class="m_b30">
                <tr v-if="showmessage.place">
                  <th>開催場所</th>
                  <td>{{ showmessage.place }}</td>
                </tr>
                <tr v-if="showmessage.reception_period && showmessage.reception_period.start_datetime">
                  <th>開始日時</th>
                  <td>{{ formatDateJp(showmessage.reception_period.start_datetime) }}</td>
                </tr>
                <tr v-if="showmessage.reception_period && showmessage.reception_period.end_datetime">
                  <th>終了日時</th>
                  <td>{{ formatDateJp(showmessage.reception_period.end_datetime) }}</td>
                </tr>
                <tr>
                  <th>参加人数<br>(参加申請/最大)</th>
                  <td>{{ showmessage.reserved }}名/<span v-if="showmessage.capacity != null">{{ showmessage.capacity }}名</span><span v-else>設定なし</span></td>
                </tr>
                <tr v-if="showmessage.contact">
                  <th>問い合わせ先</th>
                  <td>{{ showmessage.contact }}</td>
                </tr>
              </table>
              <div class="t_c m_b50" v-if="showmessage.event_end == false">
                <div v-if="entry_possible" class="btn_large">
                  <button class="btn btn-primary" @click="entryPost">参加申し込み</button>
                </div>
                <div v-else class="btn_large apply">
                  <button class="btn btn-primary">申し込み済</button>
                </div>
              </div>

              <h2>詳細</h2>
              <div v-html="showmessage.content"></div>

              <div v-if="files.length > 0" class="m_t80">
                <h3>関係ファイルダウンロード</h3>
                <ul class="doc_list">
                  <li class="doc_wrap" v-for="file in files" :key="file.id">
                    <div class="doc_left">
                      <p>{{ file.display_name }}</p>
                    </div>
                    <div class="doc_right">
                      <div class="btn_norm">
                        <a v-on:click="dlfile(file.storage_file_name)" class="en" target="_blank" :download="file.updaload_name">DL</a>
                      </div>
                    </div>  
                  </li>
                </ul>
              </div>

            </article>
            <div class="t_c m_t100 m_b50" v-if="showmessage.event_end == false">
              <div v-if="entry_possible" class="btn_large">
                <button class="btn btn-primary" @click="entryPost">参加申し込み</button>
              </div>
              <div v-else class="btn_large apply">
                <button class="btn btn-primary">申し込み済</button>
              </div>
            </div>


            <div class="t_c m_t100 m_b50">
              <div class="btn_wide blue">
                <!-- <a style="cursor: pointer">もどる</a> -->
                <router-link :to="{ name: 'Front event archive', params: { type: 'event' }}">もどる</router-link>
              </div>
            </div>
          </div>
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/bard01.png" alt="青鳥"></div>
          <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/bard02.png" alt="緑鳥"></div>
        </div>
      </div>
      <div class="kusa_outline">
        <div class="kusa_area">
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap02"><img src="@/assets/front_component/images/assets/kusa02.png" alt="草"></div>
          <div class="deco_wrap03"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap04"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap06"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap07"><img src="@/assets/front_component/images/assets/kusa05.png" alt="草"></div>
          <div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap09"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap10"><img src="@/assets/front_component/images/assets/kusa07.png" alt="草"></div>
          <div class="deco_wrap11"><img src="@/assets/front_component/images/assets/kusa06.png" alt="草"></div>
          <div class="deco_wrap12"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
          <div class="deco_wrap13"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap14"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap15"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
          <div class="deco_wrap16"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
          <div class="deco_wrap17"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
        </div>
      </div>
      <div class="deco_outline">
        <div class="deco_area">
          <div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球"></div>
          <div class="deco_wrap02">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap03">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap04"><img src="@/assets/front_component/images/assets/heri01.png" alt="ヘリコプター"></div>
          <div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球"></div>
          <div class="deco_wrap06">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap07">
            <img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
            <img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
            <img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
          </div>
          <div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球"></div>
        </div>
      </div>
    </div>
  </main>
  <div class="menu_wrap">
    <div class="menu_icon">
      <img src="@/assets/front_component/images/assets/menu01.png" alt="メニュー">
    </div>
    <div class="menu_list_wrap">
      <div class="menu_ttl">作成する</div>
      <ul class="menu_list">
          <!-- <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'news' }}">お知らせ</router-link></li> -->
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'thanks' }}">サンクス&ホメロンカード</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'idea' }}">アイディアカード</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'daily' }}">気づき日報</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'photolog' }}">フォトログ</router-link></li>
          <li><router-link :to="{ name: 'Control messagecreate', params: { type: 'column' }}">コラム</router-link></li>
          
      </ul>
    </div>
  </div>
</template>

<script>
import Api from "@/apis/Api";
import "@/assets/front_component/js/functions.js";
import $ from 'jquery';
import message from "@/apis/Message";
import event from "@/apis/Event";
import user from "@/apis/User";
//import $ from "jquery";
import MessageColumn from "@/const/MessageColumn.json";
import MessageType from "@/const/MessageType.json";
import NewsUtility from "@/apis/News";
import dayjs from 'dayjs';

export default {
  name: "message",
  data() {
    return {
      userlists:"",
      showmessage: {},
      MessageColumn() {
      return MessageColumn
      },
      messagecolumn: {},
      MessageType() {
      return MessageType
      },
      messagetype: {},
      from_user: {},
      entry_user: {},
      entry_possible: true,
      files: {},
      iconPath: "",
    };
  },
  components: {},
  beforeCreate(){
    $(function(){
      // body に open クラスをつけたりはずしたりする( open クラスは空)
      $('.g_header_list').removeClass('open');
      $('#button').removeClass('active');
      $(document.body) .removeClass('open');
      var result = $('body').attr('style');
      var int_data = result.replace(/[^0-9]/g, '');
      $("html,body").css({
        "overflow": "auto",
        "height": "auto",
      });
      //bodyfixedを解除する
      $('body').css({
        'position': '',
        'width': '',
        'top': ''
      });
      $(window).scrollTop(int_data); 
      $('.g_header_list  > li.has_child').removeClass('open');
      $('.g_header_list  > li.has_child').find('.menu_outwrap').slideUp();
    });
    $(function(){
      $('#js-loader').show();
      $(window).scrollTop(0);
    });
  },
  created() {
    console.log("event from index");

    this.message_type = 'event';
    this.message_id = this.$route.params.id;
    this.messagecolumn = MessageColumn[this.message_type];
    this.messagetype = MessageType[this.message_type];
    this.iconPath = require("@/assets/front_component/images/assets/" + this.messagetype.imgPath);
    this.ShowMessage(this.message_id);
    this.getFile(this.message_id);
    this.getMessageEyecatch(this.message_id);
  },
  mounted() {
    document.body.className = 'page_control';
    this.$nextTick(function() {
      $(function(){
        $('.menu_icon').click(function(){
          if($('.menu_list_wrap').hasClass('show')){
            $('.menu_list_wrap').removeClass('show');
          }else{
            $('.menu_list_wrap').addClass('show');
          }
        });
      });
      $(document).click(function(event) {
        if(!$(event.target).closest('.menu_wrap').length) {
          $('.menu_list_wrap').removeClass('show');
        }
      });
      $(function(){
        var content_height = $("#app").height(); // コンテンツの高さを取得
        var pagetop_show = content_height - 1500; // ページトップを出すの高さを設定
        $(window).on("scroll", function () {
          var scroll = $(window).scrollTop();
          var windowHeight = $(window).height();
          //ページトップ表示
          if(scroll > pagetop_show && scroll>60){
            $('.pagetop_wrap').addClass('show');
          } else {
            $('.pagetop_wrap').removeClass('show');
          }
        });
      });
      $(function(){
        $('#js-loader').delay(300).fadeOut(600);
        $(window).scrollTop(0);
      });
      //Jquery
      // youtubeがタグの構成上表示出来ないので以下でタグを変換している
      $(function () {
        $("figure.media").each(function () {
          var $y_url = $(this).children().attr("url").replace("https://youtu.be/", "");
          $y_url = $(this).children().attr("url").replace("https://www.youtube.com/watch?v=", "");
          $(this)
            .children()
            .replaceWith(
              "<iframe class='post_youtube' title='YouTube video player' frameborder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' allowfullscreen></iframe>"
            );
          $(this)
            .children(".post_youtube")
            .attr("src", "https://www.youtube.com/embed/" + $y_url);
        });
      });
    });
  },
  methods: {
    getFromUser(user_id) {
      user.show(user_id)
      .then((response) => {
        if (response != null) {
          this.from_user = response.data.data;
        }
      })
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {
      });
    },
    formatDate: dateStr => dayjs(dateStr).format('YYYY.MM.DD'),
    formatDate_time: dateStr => dayjs(dateStr).format('YYYY-MM-DD'),
    formattime: dateStr => dayjs(dateStr).format('YYYY-MM-DD hh:mm:ss'),
    formatDateJp: dateStr => dayjs(dateStr).format('YYYY年MM月DD日 hh:mm'),
    ShowMessage(id) {
      event.show(id)
      .then((response) => {
        if (response != null) {
          this.showmessage = response.data.data;
          if(this.showmessage.create_user_id != null){
            this.getFromUser(this.showmessage.create_user_id);
          }
          this.entryGet(this.showmessage.id);
          var now = new Date();
          this.showmessage.event_end = false;
          if(now.toISOString() > this.showmessage.implementation_datetime.end_datetime){
            this.showmessage.event_end = true;
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {
      });
    },
    getFile(message_id) {
      event.showfile(message_id, "key", "event_attached_file")
      .then(
        (response) => {
          if (response != "") {
            this.files = response.data.data;
          }
        },
        (error) => {
          console.log(error);
          this.$router.push({ name: 'Front Error' });
        }
      )
      .catch(() => {
        this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {});
    },
    getMessageEyecatch(message_id) {
		
			event.showfile(message_id,'key','eyecatch')
			.then((response) => {
        if (response != null) {
					this.showmessage.eyecatch = response.data.data[0];
					let fileApiPass = '/api/v1/file/' + this.showmessage.eyecatch.storage_file_name;
					let img = document.getElementById('img_tag_id');
					img.src = 'data:image/jpeg;base64,' + this.showmessage.eyecatch.base64;
				}

			})
			.catch((error) => {
				console.log(error);
			})
			.finally(() => {
			});
      
    },
    entryGet(id){
      event.entryGet(id)
      .then(
        (response) => {
          if (response != "") {
            this.entry_user = response.data.data;
            this.entry_user.forEach((ob) => {
              if(ob.from_id == this.$User.id){
                this.entry_possible = false;
              }
            });
          }
        },
        (error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error' });
        }
      )
      .catch(() => {
        this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {});
    },
    entryPost(){
      var post_json = {
        "forms":[]
      };
      event.entryPost(post_json,this.$route.params.id)
      .then(
        (response) => {
          if (response != "") {
            console.log(response.data.data);
          }
          this.$router.push({ name: 'Front event show', params: { type: ("event"),id: (this.$route.params.id) }});
          location.reload(true);
        },
        (error) => {
          console.log(error);
          //this.$router.push({ name: 'Front Error' });
        }
      )
      .catch(() => {
        this.$router.push({ name: 'Front Error Catch' });
      })
      .finally(() => {});
    },
    dlfile(path) {
      this.windowReference = window.open();
      //let fileApiPass = '/api/v1/file/adedd8819fecb6f2abd2756a7771803d?attachment';
      let fileApiPass = "/api/v1/file/" + path;
      console.log(fileApiPass);
      Api()
        .get(fileApiPass, { responseType: "arraybuffer" })
        .then((response) => {
          let blob = new Blob([response.data], {
            type: response.headers["content-type"],
          });
          /**
           * 新規タブ
           */
          this.windowReference.location = window.URL.createObjectURL(blob);
          /**
           * 疑似ダウンロード
           */
          //let link= document.createElement('a')
          //link.href= window.URL.createObjectURL(blob)
          //link.download= response.headers['content-filename']
          // // 以下コメントアウトで実行
          // link.click()
        });
    },
  }
};
</script>

<style scoped>
/* 外部CSSスタイルシートを追加する */
</style>
